import { ShippingMethodCode } from '../../../../contexts'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const SHIPPINGS_COMPONENT_KEY = 'ShippingsComponent'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export interface ShippingsConfig {
  drivers?: string[]
  shippingIcons: Record<string, string> | null
  usePriceIncludingTaxForMethod: boolean
}

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const defaultConfig: ShippingsConfig = {
  drivers: undefined,
  shippingIcons: {
    [ShippingMethodCode.Flatrate]: require('./assets/images/dhl.svg'),
    [ShippingMethodCode.DHLKurier]: require('./assets/images/dhl.svg'),
    [ShippingMethodCode.DPDKurier]: require('./assets/images/dpd.svg'),
    [ShippingMethodCode.Free]: require('./assets/images/dhl.svg'),
    // [ShippingMethodCode.InpostKurier]: require('./assets/images/inpost.svg'),
    [ShippingMethodCode.OrlenPaczkaPobranie]: require('./assets/images/orlen.svg'),
    [ShippingMethodCode.OrlenPaczka]: require('./assets/images/orlen.svg'),
    [ShippingMethodCode.Paczkomat]: require('./assets/images/inpost.svg'),
    [ShippingMethodCode.PaczkomatCod]: require('./assets/images/inpost.svg'),
    [ShippingMethodCode.DPD]: require('./assets/images/dpd.svg'),
    [ShippingMethodCode.DPDCod]: require('./assets/images/dpd.svg'),
    [ShippingMethodCode.AramexCreditsCardsDelivery]: require('./assets/images/aramex.png'),
    [ShippingMethodCode.AramexOverNightDocument]: require('./assets/images/aramex.png'),
    [ShippingMethodCode.AramexSameDayDocument]: require('./assets/images/aramex.png'),
    [ShippingMethodCode.AramexOverNightParcel]: require('./assets/images/aramex.png')
    // [ShippingMethodCode.UPS]: ''
  },
  usePriceIncludingTaxForMethod: false
}
