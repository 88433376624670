import { ShippingMethodCode } from '../../../../contexts'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const SHIPPING_STEP_COMPONENT_KEY = 'ShippingStepComponent'

export interface ShippingStepConfig {
  buttonGoNextTheme: string
  shippingIcons: Record<string, string> | null
  icons: unknown
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultConfig: ShippingStepConfig = {
  buttonGoNextTheme: 'secondary',
  shippingIcons: {
    [ShippingMethodCode.Flatrate]: require('./assets/images/dhl.svg'),
    [ShippingMethodCode.DHLKurier]: require('./assets/images/dhl.svg'),
    [ShippingMethodCode.DPDKurier]: require('./assets/images/dpd.svg'),
    [ShippingMethodCode.Free]: require('./assets/images/dhl.svg'),
    // [ShippingMethodCode.InpostKurier]: require('./assets/images/inpost.svg'),
    [ShippingMethodCode.OrlenPaczkaPobranie]: require('./assets/images/orlen.svg'),
    [ShippingMethodCode.OrlenPaczka]: require('./assets/images/orlen.svg'),
    [ShippingMethodCode.Paczkomat]: require('./assets/images/inpost.svg'),
    [ShippingMethodCode.PaczkomatCod]: require('./assets/images/inpost.svg'),
    [ShippingMethodCode.DPD]: require('./assets/images/dpd.svg'),
    [ShippingMethodCode.DPDCod]: require('./assets/images/dpd.svg'),
    // [ShippingMethodCode.AramexCreditsCardsDelivery]: require('./assets/images/aramex.png'),
    // [ShippingMethodCode.AramexOverNightDocument]: require('./assets/images/aramex.png'),
    // [ShippingMethodCode.AramexSameDayDocument]: require('./assets/images/aramex.png'),
    // [ShippingMethodCode.AramexOverNightParcel]: require('./assets/images/aramex.png')
  },
  icons: {}
}
