





























import { Component, Prop, PropSync } from 'vue-property-decorator'

import {
  AvailableShippingMethod,
  ShippingCarrierCode,
  ShippingMethod,
  ShippingMethodCode
} from '../../../../contexts'

import { CheckoutServiceType, ICheckoutService } from '../../services/checkout'
import { CartModel, CheckoutStepCallback } from '../../contracts'

import { defaultDriver, driversRegistry } from './drivers/drivers'
import {
  isFreeShipmentThresholdReached,
  supportedShippingMethods
} from './Shippings.helpers'
import { Inject } from '../../../../support'
import { Loader } from '../../../shared/molecules/Loader'
import StructureConfigurableMixin from '../../../../support/mixins/StructureConfigurable.mixin.vue'
import { defaultConfig, SHIPPINGS_COMPONENT_KEY } from './Shippings.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Shippings>({
  name: 'Shippings',
  components: {
    Loader
  },
  mounted () {
    this.loadAvailableShippingMethods(this.cart.id)
  },
  created (): void {
    this.config = this.getComponentConfig(SHIPPINGS_COMPONENT_KEY, defaultConfig)
  }
})
export class Shippings extends StructureConfigurableMixin {
  @PropSync('errors', { type: Array, required: false, default: () => ([]) })
  public _errors!: string[]

  @PropSync('selected', { type: Object, required: false, default: null })
  public _selected!: ShippingMethod | null

  @PropSync('callbacks', { type: Array, required: true })
  public _callbacks!: CheckoutStepCallback[]

  @Prop({ type: Object, required: true })
  protected readonly cart!: CartModel

  @Inject(CheckoutServiceType)
  protected readonly checkoutService!: ICheckoutService

  public availableShippingMethods: AvailableShippingMethod[] = []

  public isLoading = true

  public get availableMethods (): string[] {
    return this.getConfigProperty<string[]>('drivers') ?? supportedShippingMethods
  }

  public get shippingIcons (): Record<string, string> {
    return this.getConfigProperty('shippingIcons')
  }

  public get usePriceIncludingTaxForMethod (): boolean {
    return this.getConfigProperty('usePriceIncludingTaxForMethod')
  }

  public async loadAvailableShippingMethods (cardId: string) {
    this.isLoading = true

    this.availableShippingMethods = await this.checkoutService.loadAvailableShippingMethods(
      cardId, this.usePriceIncludingTaxForMethod).finally(() => {
      this.isLoading = false
    })
  }

  public get methods () {
    const considerFreeShipment: boolean = isFreeShipmentThresholdReached(this.availableShippingMethods.map((method) => method.methodCode))
    return this.availableShippingMethods
      .filter(({ methodCode }) => this.availableMethods.includes(methodCode))
      .filter((method) => {
        return considerFreeShipment ? method.price.value === 0 : true
      })
      .map(({ methodCode, price }: ShippingMethod) => ({
        data: {
          carrierCode: methodCode,
          description: this.$t(`front.checkout.organisms.ShippingStep.method.${methodCode}.description`),
          image: this.shippingIcons[methodCode],
          price,
          title: this.$t(`front.checkout.organisms.ShippingStep.method.${methodCode}.title`)
        },
        driver: methodCode in driversRegistry ? driversRegistry[methodCode] : defaultDriver,
        id: methodCode
      }))
  }

  public get selectedShippingCode (): ShippingCarrierCode | null {
    return this._selected?.methodCode ?? null
  }

  public set selectedShippingCode (value: ShippingMethodCode | null) {
    this._selected = this.availableShippingMethods.find(method => method.methodCode === value) ?? null
    this._errors = []
  }
}
export default Shippings
