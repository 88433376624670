
















import { Component, Mixins } from 'vue-property-decorator'

import { ShippingMethod } from '../../../../contexts'
import { StructureConfigurable } from '../../../../support/mixins'

import { AbstractStepPreview } from '../AbstractStepPreview'
import {
  defaultConfig,
  SHIPPING_STEP_COMPONENT_KEY,
  ShippingStepConfig
} from './ShippingStep.config'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ShippingStepPreview>({
  name: 'ShippingStepPreview',
  created () {
    this.config = this.getComponentConfig(SHIPPING_STEP_COMPONENT_KEY, defaultConfig)
  }
})
export class ShippingStepPreview extends Mixins(AbstractStepPreview, StructureConfigurable) {
  protected config!: ShippingStepConfig
  private method: ShippingMethod | null = this.payload?.shipping ?? null

  public get shippingIcons (): Record<string, string> | null {
    return this.getConfigProperty('shippingIcons')
  }

  public get icon (): string | null {
    if (!this.method) {
      return null
    }

    return this.shippingIcons ? this.shippingIcons[this.method.methodCode] : null
  }

  public get name (): string | null {
    if (!this.method) {
      return null
    }

    return this.$t(`front.checkout.organisms.ShippingStep.method.${this.method.methodCode}.title`)
      .toString()
  }

  public get price (): number {
    return this.method ? this.method.price.value : 0
  }
}

export default ShippingStepPreview
